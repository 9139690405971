<template>
  <div></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { notify } from '../../utils/notification'

export default {
  name: 'Logout',
  methods: {
    ...mapActions(['signOut', 'clearNotifications'])
  },
  computed: mapGetters(['user', 'loading', 'notification']),
  created: function () {
    this.signOut()
    notify(this)
    this.$router.push('/')
  }
}
</script>
